$small: 600px;

// For hiding scroll bar when dialog is open
.no-scroll {
    overflow: hidden !important;
}

.event-upload-image {
    width: 100%;
    height: 100%;
    // object-fit: cover;

    @media screen and (min-width: $small) {
        border-radius: 15px;
    }
}

.event-upload-album {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
}

.menubar-sticky {
    z-index: 100;
    position: sticky !important;

    top: 0px !important; // As app bar gets hide on scroll this is required

    // @media screen and (max-width: $small) {
    //     top: 56px !important;
    // }

    // top: 64px !important;
}

// .menubar-addTabBtn {
//     @media screen and (max-width: $small) {
//         position: absolute !important;
//         right: 0 !important;
//     }
// }

.event-ticket-desc {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
}

.event-desc {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical !important;
}

.gallery-image {
    object-fit: cover !important;
}

.react-simple-image-viewer__close {
    opacity: 1 !important;
}

.react-simple-image-viewer__previous,
.react-simple-image-viewer__next {
    opacity: 0.3 !important;
    height: 25% !important;
}

.MuiPickersDay-root.Mui-selected {
    background-color: #FF8359 !important;
}

// For shadow inside image
// .shadow {
//     position: absolute !important;
//     width: 100% !important;
//     height: 100% !important;
//     box-shadow: inset 0px -10px 0px 0px rgb(0, 0, 0, 0.9) !important;
//     border-radius: 0.5rem !important;
//     top: 0 !important;
//     left: 0 !important;
// }

.event-upload-csv-drag-drop-area{
    width: 100% !important;
    height: 8rem !important;
    margin: auto !important;
    max-width: 100% !important;
    @media screen and (max-width: $small) {
        max-width: 100% !important;
    }
}

.whatsapp-ticket {
    margin: auto;
    max-width: 90%;
    @media (min-width: 1200px) {
        max-width: 70% !important;
    }
    @media (min-width: 900px) {
        max-width: 80%;
    }
}