$small: 600px;
$medium: 600px;

.btn-position {
    @media screen and (max-width: $small) {
        // position: absolute;
        // left: 50%;
        // bottom: 0;
        // transform: translate(-50%, 0%);
        position: fixed;
        left: 1rem;
        right: 1rem;
        bottom: 1rem;
    }

    text-align: center;
}