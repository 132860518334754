$small: 600px;
$medium: 600px;

.otp-input-style {
    @media screen and (max-width: $small) {
        width: 2rem !important;
        height: 2rem;
    }

    @media screen and (min-width: $medium) {
        width: 2rem !important;
        height: 2.3rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    border-radius: 0.5rem;
    border: 0rem;
    background: #dddddd;
}