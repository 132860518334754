.auth-layout-wowsly-girl-leftside{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
    background-color: lightblue;
}

.auth-layout-wowsly-girl-mobile-common{
    flex: 1 1 auto !important;
    background-color: #F3F3F3;
}

.auth-layout-wowsly-girl-mobile-background-image{
    @media (max-width: 900px)  {
        background-image: url('../assets/Onboard-1.webp');
        background-repeat: no-repeat;
        @media (max-width: 600px) {
            background-size: 90%;
            background-position: center;
        }
        background-size: 50%;
        background-position: bottom;
    }
    }

.auth-layout-right-side-container{
    height: 100vh;
    overflow-y: "auto";
}
