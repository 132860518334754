$extra-small: '375px';
$small: '600px';
$medium: '900px';

* {
    box-sizing: border-box;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    @media screen and (min-width: $small) {
        display: none;
    }
}

.react-horizontal-scrolling-menu--scroll-container {
    align-items: center !important;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.react-horizontal-scrolling-menu--arrow-left,
.react-horizontal-scrolling-menu--arrow-right {
    @media screen and (max-width: $small) {
        display: none;
    }

    align-items: center;
}

.scrollContainer {
    @media screen and (max-width: $extra-small) {
        width: 95vw !important;
    }

    @media screen and (min-width: $extra-small) and (max-width: $medium) {
        width: 95vw !important;
    }
}

// .react-horizontal-scrolling-menu--wrapper {
//     @media screen and (max-width: $small) {
//         width: 95vw !important;
//     }
// }