.gradientBtn {
    background-image: linear-gradient(to left, #FF5F6D 0%, #FFC371 51%, #FF5F6D 100%);
    text-transform: none !important;
    transition: 0.5s !important;
    background-size: 200% auto !important;
    color: white !important;
    box-shadow: 0 0 20px #eee !important;
    border-radius: 10px !important;
}

.gradientBtn:hover {
    background-position: right center !important;
    /* change the direction of the change here */
    color: #fff !important;
    text-decoration: none !important;
}