$small: 600px;

.details-form {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.profile-img-preview {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    min-height: 98%;
    min-width: 98%;
    border-radius: 20px;
    transform: translate(-50%, -50%);
    object-fit: cover;
}

.dash-around {
    width: 60% !important;
    text-align: center !important;
    border-bottom: 1px solid grey !important;
    border-left: 1rem !important;
    line-height: 0.1em !important;
    margin: 1rem 0rem 1rem !important;

    span {
        background: #fff;
        padding: 0 10px;
    }
}

.details-submit-btn {
    @media screen and (max-width: $small) {
        margin-top: 0.5rem !important;
        // padding-bottom: 20rem !important;
    }
}