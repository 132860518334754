.greyText {
    color: #6F738B;
}

.dashboard-event-title{
    word-break: break-word;
    font-weight: 600 !important;
    font-size: 0.95rem !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
}