.toast-position {

  width: 22rem !important;
  line-height: 1.6rem !important;

  @media screen and (max-width: 600px) {
    width: 20rem !important;
    left: 50% !important;
    top: 8% !important;
    transform: translate(-50%, -50%) !important;
  }
}
.profile-photo, .qa-answer{
  margin-left: 90px;
  @media screen and (max-width: 526px) {
    margin-left: 0 !important;
  }
}