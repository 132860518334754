body {
  margin: 0;
  padding: 0 !important;
  overflow: auto !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
.blurred-input {
  color: transparent !important;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.9);
}
.generated-token{
  height: 10px;
}