$small: 600px;

.group-upload-csv-drag-drop-area{
    width: 100% !important;
    height: 8rem !important;
    margin: auto !important;
    max-width: 100% !important;
    display: block;
    @media screen and (max-width: $small) {
        max-width: 100% !important;
    }
}